import React from 'react';
import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    emphasize,
    IconButton,
    Menu,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import axios from 'axios';
import { connect } from 'react-redux';
import { handleError } from '../../../components/snackbar/reducer';
import { bindActionCreators } from 'redux';
import emojiData from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import EmoticonIcon from '@mui/icons-material/InsertEmoticon';
import SendIcon from '@mui/icons-material/Send';
import { styled } from '@mui/material/styles';
import { getTimezoneForProfile } from '../../../utils/timezone';
import { openChat, setAttachedChatObjects, openMsgs } from '../reducer';
import moment from 'moment-timezone';
import TimeAgo from 'timeago-react';
import Request from './objects/request';
import RemoveIcon from '@mui/icons-material/Remove';
import { PlatformAvatar } from '../../../components/PlatformAvatar';
import * as Sentry from '@sentry/browser';
import { resolveFullTimeFormat } from '../../../utils/i18n';
import { Delete } from '@mui/icons-material';
import { MobileNotificationHeader } from '..';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';

const InputContainer = styled('div')`
    display: flex;
    flex-direction: column;
    padding: 10px 10px;
    border: 1px solid ${(props) => emphasize(props.theme.palette.background.paper, 0.2)};
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: #2c324a;
    @media (max-width: 1024px) {
        position: fixed;
        z-index: 1000;
        // height: 17.5vh;
    }
`;

export const ChatContentContainer = styled('div')`
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    @media (max-width: 1024px) {
        flex-direction: column-reverse;
        height: 100%;
    }
`;

export const LeftColumn = styled('div')`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    @media (max-width: 1024px) {
        height: 100%;
    }
`;

export const MessageContainer = styled('div')`
    flex-grow: 1;
    display: flex;
    // @media (max-width: 1024px) {
    //     height: 63vh;
    // }
`;

export const StyledTimeAgo = styled(TimeAgo)`
    color: ${(props) => props.theme.palette.text.secondary};
    font-size: 80%;
`;

export const Scrollable = styled('div')`
    flex: 1 1 auto;
    overflow-y: auto;
    overflow-x: hidden;
    min-height: 0;
    max-height: 71vh;

    z-index: 1;

    &:before,
    &:after {
        content: '';
        position: relative;
        z-index: 0;
        display: block;
        height: 30px;
        margin: 0 0 -30px;
    }

    &:after {
        margin: -30px 0 0;
    }
`;

export const ScrollableMessageContainer = styled('div')`
    padding: 10px 10px 0 10px;
    position: relative;
    z-index: 2;
    min-height: 30px;
    @media (max-width: 1024px) {
        height: 52vh;
        padding-bottom: 5rem;
    }
`;

const MessageObjectContainer = styled('div')`
    // padding: 5px;
    // background-color: rgb(60, 64, 81);
    // margin-top: 1px;
    // border: 1px solid ${(props) => emphasize(props.theme.palette.background.paper, 0.2)};
`;

const UnloadedContainer = styled('div')`
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-top: 20px;
`;

const RemoveIconWrapper = styled('div')`
    @media (max-width: 1024px) {
        display: none;
    }
`;

class ChatContent extends React.Component {
    state = {
        isLoading: false,
        chat: null,
        actingTeamId: null,

        emojiAnchorEl: null,
        messageText: '',

        messages: [],
        isMobile: window.innerWidth <= 767,
    };

    inputRef = null;
    scrollContainerRef = null;
    subscriptions = [];

    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
        this.scrollContainerRef = React.createRef();
    }

    async componentDidMount() {
        this.subscriptions.push(
            this.props.websocket.subscribeConnectionStatus((connected) => {
                if (connected && this.props.chatId) {
                    this.subscribeChat(this.props.chatId);
                }
            }),
            this.props.websocket.subscribe(async (event) => {
                if (event.MessageType === 'chat.NewMessage' && event.Payload.ChatID === this.props.chatId) {
                    this.setState((prevState) => ({
                        messages: [...prevState.messages, event.Payload.Message],
                    }));
                    this.readChatId = null;

                    if (event.Payload.Message.Participant.ID !== this.props.profile.ID) {
                        await this.markChatRead();
                    }
                } else if (event.MessageType === 'chat.InitialMessages' && event.Payload.ChatID === this.props.chatId) {
                    this.setState({
                        messages: event.Payload.Messages,
                    });
                }
            })
        );

        await this.updateChat();

        setTimeout(() => this.scrollToNewestMessage(), 100);

        window.addEventListener('resize', this.handleWindowResize);
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.chatId !== this.props.chatId) {
            if (prevProps.chatId) {
                this.unsubscribeChat(prevProps.chatId);
            }

            if (this.props.chatId) {
                this.subscribeChat(this.props.chatId);
                await this.updateChat();
            }
        }

        if (prevState.chat === null && this.state.chat !== null) {
            setTimeout(() => {
                if (!this.inputRef.current) {
                    return;
                }

                this.inputRef.current.focus();
            }, 100);
        }

        if (prevState.chat !== this.state.chat) {
            this.markChatRead();
        }

        if (prevState.chat !== this.state.chat || prevProps.profile !== this.props.profile) {
            this.updateActingTeam();
        }

        if (prevState.messages !== this.state.messages) {
            setTimeout(() => this.scrollToNewestMessage(), 100);
        }
    }

    componentWillUnmount() {
        if (this.props.chatId) {
            this.unsubscribeChat(this.props.chatId);
        }

        for (const sub of this.subscriptions) {
            sub.unsubscribe();
        }

        window.removeEventListener('resize', this.handleWindowResize);
    }

    handleWindowResize = () => {
        this.setState({
            isMobile: window.innerWidth <= 767,
        });
    };

    readChatId = null;

    async markChatRead() {
        const { chat } = this.state;
        if (!chat || this.readChatId === chat.ID) {
            return;
        }
        this.readChatId = chat.ID;

        try {
            await axios.put('/api/profile/chats/' + chat.ID + '/read');
        } catch (e) {
            Sentry.captureException(e);
        }
    }

    updateActingTeam() {
        let actingTeamId = null;

        const { profile } = this.props;
        const { chat } = this.state;

        if (profile && chat) {
            const selectedTeam = chat.Teams.find((team) => team.ID === profile.Team.ID);
            if (selectedTeam) {
                actingTeamId = selectedTeam.ID;
            } else {
                const anyTeam = chat.Teams.find((team) => profile.Teams.findIndex((t) => t.ID === team.ID) >= 0);
                if (anyTeam) {
                    actingTeamId = anyTeam.ID;
                }
            }
        }

        this.setState({ actingTeamId });
    }

    scrollToNewestMessage() {
        if (this.scrollContainerRef.current) {
            const container = this.scrollContainerRef.current;
            container.scrollTop = container.scrollHeight;
        }
    }

    subscribeChat(chatId) {
        this.props.websocket.send('chat.Subscribe', {
            ChatID: chatId,
        });
    }

    unsubscribeChat(chatId) {
        this.props.websocket.send('chat.Unsubscribe', {
            ChatID: chatId,
        });
    }

    async updateChat() {
        if (this.state.isLoading) {
            return;
        }

        const { chatId } = this.props;
        if (!chatId) {
            return;
        }
        const { chat } = this.state;
        if (chat && chat.ID === chatId) {
            return;
        }

        this.setState({ isLoading: true, chat: null });
        try {
            const res = await axios.get('/api/chat/' + chatId);
            this.setState({
                chat: res.data,
            });
        } catch (e) {
            this.props.handleError(e);
        } finally {
            this.setState({ isLoading: false });
        }
    }

    handleSend() {
        const text = this.state.messageText.trim();
        if (text === '') {
            return;
        }

        this.props.websocket.send('chat.SendMessage', {
            ChatID: this.props.chatId,
            Message: text,
            ReferencedObjects: this.props.messageObjects,
        });
        this.setState({
            messageText: '',
        });
        this.props.setAttachedChatObjects({});
    }

    render() {
        const { isLoading, chat, emojiAnchorEl } = this.state;
        if (isLoading) {
            return (
                <UnloadedContainer>
                    <CircularProgress size={30} />
                </UnloadedContainer>
            );
        }

        if (!chat) {
            return (
                <UnloadedContainer>
                    Failed to load chat.
                    <Button color="secondary" variant="outlined" onClick={() => this.updateChat()}>
                        Reload Chat
                    </Button>
                </UnloadedContainer>
            );
        }

        const selectedTeam = chat.Teams.find(
            (team) => this.props.profile.Teams.findIndex((t) => t.ID === team.ID) >= 0
        );

        const otherTeam = chat.Teams.find((team) => selectedTeam.ID !== team.ID);

        return (
            <React.Fragment
                sx={{
                    marginLeft: '25rem',
                }}
            >
                <MobileNotificationHeader
                    sx={{
                        backgroundColor: '#2c324a',
                        minHeight: 80,
                        '@media (max-width: 1024px)': {
                            height: '7rem',
                        },
                    }}
                    style={{
                        padding: '0.4rem',
                    }}
                >
                    <Box
                        style={{
                            width: '100%',
                        }}
                    >
                        <Box
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                width: '100%',
                            }}
                        >
                            <Box
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '0.5rem',
                                    alignItems: 'center',
                                }}
                            >
                                <Avatar
                                    style={{
                                        width: '30px',
                                        height: '30px',
                                    }}
                                    src={otherTeam.Logo}
                                />
                                <Typography variant="p" sx={{ fontWeight: 700 }}>
                                    {otherTeam.Name}
                                </Typography>
                            </Box>

                            <Box
                                style={{
                                    padding: '2px',
                                    // backgroundColor: 'rgb(60, 64, 81)',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <React.Fragment key={otherTeam.ID}>
                                    {otherTeam.Links.length > 0 && (
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            {otherTeam.Links.map((link) => (
                                                <a
                                                    href={link.Url}
                                                    key={link.Type}
                                                    target="_blank"
                                                    style={{ marginRight: '5px' }}
                                                >
                                                    <PlatformAvatar
                                                        style={{
                                                            width: '24px',
                                                            height: '24px',
                                                        }}
                                                        platform={link.Type}
                                                    />
                                                </a>
                                            ))}
                                        </div>
                                    )}
                                </React.Fragment>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                width: '100%',
                            }}
                        >
                            <Box>
                                <div>{this.renderMessageObjects(this.state.messages[0], true)}</div>
                                {!this.state.isMobile && this.renderAttachedObjects()}

                                {this.state.isMobile && (
                                    <React.Fragment>
                                        <div
                                            style={{
                                                position: 'relative',
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                // minHeight: 40,
                                            }}
                                        >
                                            {this.renderAttachedObjects()}
                                            {/* <div style={{ position: 'absolute', top: 0, right: 0 }}>
                                        <Button variant={'contained'} color={'secondary'} onClick={() => this.hideChat(chat)}>
                                            <Delete />
                                        </Button>
                                    </div> */}
                                        </div>
                                        {/* {chat.Teams.map((team) => {
                                    if (team.ID !== this.props.profile.Team.ID && team.Links.length > 0) {
                                        return (
                                            <React.Fragment key={team.ID}>
                                                <ParticipantHeader>
                                                    <ParticipantDetails>
                                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                            {team.Links.map((link) => (
                                                                <a
                                                                    href={link.Url}
                                                                    key={link.Type}
                                                                    target="_blank"
                                                                    style={{ marginRight: '5px' }}
                                                                >
                                                                    <PlatformAvatar
                                                                        style={{ width: '24px', height: '24px' }}
                                                                        platform={link.Type}
                                                                    />
                                                                </a>
                                                            ))}
                                                        </div>
                                                    </ParticipantDetails>
                                                </ParticipantHeader>
                                            </React.Fragment>
                                        );
                                    }
                                })} */}
                                    </React.Fragment>
                                )}

                                <Box
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        width: '100%',
                                    }}
                                >
                                    {this.renderMessageObjects(this.state.messages[0], false)}
                                </Box>
                            </Box>
                            <Tooltip title="Temporary Delete">
                                <IconButton onClick={() => this.hideChat(chat)}>
                                    <Delete />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Box>

                    {/* <IconButton
                        onClick={() => {
                            this.setState({ viewChat: false });
                        }}
                    >
                        <CloseIcon />
                    </IconButton> */}
                </MobileNotificationHeader>

                <ChatContentContainer>
                    <LeftColumn>
                        <MessageContainer>
                            <Scrollable ref={this.scrollContainerRef}>
                                <ScrollableMessageContainer>
                                    {this.state.messages.map((message) => {
                                        const isUserInTeam = selectedTeam.Members.some(
                                            (member) => member.ID.toString() === String(message.Participant.ID)
                                        );

                                        return isUserInTeam ? (
                                            <Box
                                                sx={{
                                                    padding: 0.5,
                                                    display: 'flex',
                                                    width: '100%',
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-start',
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                                        boxShadow: 3,
                                                        padding: 2,
                                                        borderRadius: '10px',
                                                        width: '80%',
                                                    }}
                                                >
                                                    <Box
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'space-between',
                                                        }}
                                                    >
                                                        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                                                            <Avatar
                                                                src={message.Participant.Avatar}
                                                                style={{
                                                                    width: '25px',
                                                                    height: '25px',
                                                                }}
                                                            />
                                                            <Typography>{message.Participant.Nickname}</Typography>
                                                        </Box>

                                                        <StyledTimeAgo
                                                            datetime={message.Time}
                                                            title={moment(message.Time)
                                                                .tz(this.props.timezone)
                                                                .format(
                                                                    resolveFullTimeFormat(
                                                                        this.props.profile?.Settings?.TimeFormat
                                                                    )
                                                                )}
                                                        />
                                                    </Box>
                                                    <Typography>{message.Text}</Typography>
                                                </Box>
                                            </Box>
                                        ) : (
                                            <Box
                                                sx={{
                                                    padding: 0.5,
                                                    display: 'flex',
                                                    width: '100%',
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-end',
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                                        boxShadow: 3,
                                                        padding: 2,
                                                        borderRadius: '10px',
                                                        width: '80%',
                                                    }}
                                                >
                                                    <Box
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'space-between',
                                                        }}
                                                    >
                                                        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                                                            <Avatar
                                                                src={message.Participant.Avatar}
                                                                style={{
                                                                    width: '25px',
                                                                    height: '25px',
                                                                }}
                                                            />
                                                            <Typography>{message.Participant.Nickname}</Typography>
                                                        </Box>

                                                        <StyledTimeAgo
                                                            datetime={message.Time}
                                                            title={moment(message.Time)
                                                                .tz(this.props.timezone)
                                                                .format(
                                                                    resolveFullTimeFormat(
                                                                        this.props.profile?.Settings?.TimeFormat
                                                                    )
                                                                )}
                                                        />
                                                    </Box>
                                                    <Typography>{message.Text}</Typography>
                                                </Box>
                                            </Box>
                                        );

                                        // <div
                                        //     key={message.ID}
                                        //     style={{ display: 'flex', flexDirection: 'row', marginBottom: '10px' }}
                                        // >
                                        //     <Avatar
                                        //         src={message.Participant.Avatar}
                                        //         style={{ width: '35px', height: '35px', marginRight: '10px' }}
                                        //     />
                                        //     <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        //         <div
                                        //             style={{
                                        //                 display: 'flex',
                                        //                 flexDirection: 'row',
                                        //                 alignItems: 'center',
                                        //             }}
                                        //         >
                                        //             <div style={{ fontWeight: 'bold', marginRight: '10px' }}>
                                        //                 {message.Participant.Nickname}
                                        //             </div>

                                        //             <StyledTimeAgo
                                        //                 datetime={message.Time}
                                        //                 title={moment(message.Time)
                                        //                     .tz(this.props.timezone)
                                        //                     .format(
                                        //                         resolveFullTimeFormat(
                                        //                             this.props.profile?.Settings?.TimeFormat
                                        //                         )
                                        //                     )}
                                        //             />
                                        //         </div>
                                        //         {this.renderMessageObjects(message)}
                                        //         <div>{message.Text}</div>
                                        //     </div>
                                        // </div>
                                    })}
                                </ScrollableMessageContainer>
                            </Scrollable>
                        </MessageContainer>
                        <InputContainer>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    width: '100%',
                                    zIndex: 2,
                                    justifyContent: 'space-between',
                                }}
                            >
                                <TextField
                                    variant="standard"
                                    inputRef={this.inputRef}
                                    style={{ width: '75%' }}
                                    placeholder="Write your message"
                                    margin="dense"
                                    multiline
                                    maxRows={3}
                                    value={this.state.messageText}
                                    onKeyDown={(e) => {
                                        if (e.keyCode === 13 && !e.shiftKey) {
                                            e.preventDefault();

                                            return false;
                                        }
                                    }}
                                    onKeyUp={(e) => {
                                        if (e.keyCode === 13 && !e.shiftKey) {
                                            this.handleSend();
                                        }
                                    }}
                                    onChange={(e) => this.setState({ messageText: e.target.value })}
                                />

                                <Box sx={{ display: 'flex' }}>
                                    <IconButton color="primary" onClick={() => this.handleSend()} size="large">
                                        <SendIcon />
                                    </IconButton>
                                    <IconButton
                                        aria-owns={emojiAnchorEl ? 'emoji-picker' : undefined}
                                        aria-haspopup="true"
                                        onClick={(e) => this.setState({ emojiAnchorEl: e.currentTarget })}
                                        size="large"
                                    >
                                        <EmoticonIcon />
                                    </IconButton>
                                    <Box id="emojiDrawer" onClick={(event) => event.stopPropagation()}>
                                        <Menu
                                            id="emoji-picker"
                                            anchorEl={emojiAnchorEl}
                                            open={emojiAnchorEl !== null}
                                            onClose={() => this.setState({ emojiAnchorEl: null })}
                                        >
                                            <Picker
                                                data={emojiData}
                                                onEmojiSelect={(data) => {
                                                    this.setState({
                                                        messageText: this.state.messageText + data.native,
                                                        emojiAnchorEl: null,
                                                    });
                                                }}
                                            />

                                            {/* <EmojiPicker
                                            onEmojiSelect={(data) => {
                                                this.setState({
                                                    messageText: this.state.messageText + data.native,
                                                    emojiAnchorEl: null,
                                                });
                                            }}
                                            set="apple"
                                            data={emojiData}
                                        /> */}
                                        </Menu>
                                    </Box>
                                </Box>
                            </div>
                        </InputContainer>
                    </LeftColumn>
                    {/* <ParticipantContainer>
                        <Typography sx={{ marginLeft: 1, marginTop: 1 }}>Chat ID: {chat.ID}</Typography>
                        {chat.Teams.map((team) => {
                            return (
                                <React.Fragment key={team.ID}>
                                    <ParticipantHeader>
                                        <Avatar src={team.Logo} style={{ marginRight: '10px' }} />
                                        <ParticipantDetails>
                                            <ParticipantHeaderName>{team.Name}</ParticipantHeaderName>

                                            {team.Links.length > 0 && (
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    {team.Links.map((link) => (
                                                        <a
                                                            href={link.Url}
                                                            key={link.Type}
                                                            target="_blank"
                                                            style={{ marginRight: '5px' }}
                                                        >
                                                            <PlatformAvatar
                                                                style={{ width: '24px', height: '24px' }}
                                                                platform={link.Type}
                                                            />
                                                        </a>
                                                    ))}
                                                </div>
                                            )}
                                        </ParticipantDetails>
                                    </ParticipantHeader>

                                    <div style={{ marginLeft: '10px' }}>
                                        {team.Members.map((member) => {
                                            return (
                                                <div
                                                    key={member.ID}
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                        marginBottom: '2px',
                                                    }}
                                                >
                                                    <Avatar
                                                        src={member.Avatar}
                                                        style={{ width: '24px', height: '24px', marginRight: '5px' }}
                                                    />
                                                    {member.Nickname}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </React.Fragment>
                            );
                        })}
                        <Button variant={'contained'} color={'secondary'} fullWidth onClick={() => this.hideChat(chat)}>
                            Temporary Delete
                        </Button>
                    </ParticipantContainer> */}
                </ChatContentContainer>
            </React.Fragment>
        );
    }

    async hideChat(chat) {
        try {
            this.props.openChat(null);
            await axios.post(
                '/api/chat/hide',
                { ChatID: chat.ID },
                {
                    headers: {
                        Accept: 'application/vnd.pracc.v1+json',
                    },
                }
            );
        } catch (e) {
            this.props.handleError(e);
        }
    }

    renderMessageObjects(message, hide) {
        const objects = [];

        if (message?.RequestID) {
            objects.push(
                <MessageObjectContainer key={'request-' + message.RequestID}>
                    <Request actingTeamId={this.state.actingTeamId} id={message.RequestID} hide={hide} />
                </MessageObjectContainer>
            );
        }

        return <React.Fragment>{objects}</React.Fragment>;
    }

    renderAttachedObjects() {
        const { messageObjects } = this.props;
        const keys = Object.keys(messageObjects);
        keys.sort();

        if (keys.length <= 0) {
            return null;
        }

        return (
            <React.Fragment>
                {keys.map((key) => {
                    return (
                        <div key={key} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <div>
                                <div style={{ flexGrow: 1 }}>{this.renderObject(key, messageObjects[key], true)}</div>
                                <div style={{ flexGrow: 1 }}>{this.renderObject(key, messageObjects[key], false)}</div>
                            </div>
                            {/* 
                            <RemoveIconWrapper>
                                <IconButton
                                    onClick={() => {
                                        const newObjects = { ...messageObjects };
                                        delete newObjects[key];
                                        this.props.setAttachedChatObjects(newObjects);
                                    }}
                                    size="large"
                                >
                                    <RemoveIcon />
                                </IconButton>
                            </RemoveIconWrapper> */}
                        </div>
                    );
                })}
            </React.Fragment>
        );
    }

    renderObject(key, value, hide) {
        if (key === 'RequestID') {
            return <Request id={value} actingTeamId={this.state.actingTeamId} hide={hide} />;
        }

        console.warn('Unsupported component: ', key);

        return null;
    }
}

const Connected = connect(
    (state) => ({
        websocket: state.app.websocket,
        profile: state.app.profile,
        timezone: getTimezoneForProfile(state.app.profile),
        messageObjects: state.app.attachedChatMessageObjects,
    }),
    (dispatch) =>
        bindActionCreators(
            {
                handleError,
                openChat,
                setAttachedChatObjects,
                openMsgs,
            },
            dispatch
        )
)(ChatContent);

export default Connected;
