import React from 'react';
import styled from 'styled-components';
import {
    Avatar,
    Badge,
    Box,
    Button,
    CircularProgress,
    Divider,
    Drawer,
    Fab,
    Grid,
    IconButton,
    Typography,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import axios from 'axios';
import { connect } from 'react-redux';
import { handleError } from '../../../components/snackbar/reducer';
import { bindActionCreators } from 'redux';
import { openChat, openMsgs, setActiveChats } from '../reducer';
import * as Sentry from '@sentry/browser';
import { MobileNotificationHeader } from '..';
import CloseIcon from '@mui/icons-material/Close';
import {
    ChatContentContainer,
    LeftColumn,
    MessageContainer,
    Scrollable,
    ScrollableMessageContainer,
    StyledTimeAgo,
} from './chat-content';
import { AsyncChatContent } from './chat-container';
import moment from 'moment';
import { resolveTimeFormatWithWeekdaySt } from 'utils/i18n';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';

const ChatButtonAvatar = styled(Avatar)`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    transition: border-radius 0.3s ease;
    background-color: #222222;
    color: white;
    &:hover {
        border-radius: 15%;
    }
`;

const ChatButtonBadge = withStyles((theme) => ({
    badge: {
        top: '11px',
        right: '9px',
    },
}))(Badge);

class ChatDrawer extends React.Component {
    state = {
        isLoading: false,
        hasLoadError: false,
        activeChats: [],
        viewChat: true,
    };

    subscriptions = [];

    async componentWillMount() {
        if (this.props.profile) {
            await this.updateActiveChats();
        }

        this.subscriptions.push(
            this.props.websocket.subscribe((event) => {
                if (event.MessageType === 'chat.ResetUnreadMessages') {
                    this.applyChangedChats((currentChats) =>
                        currentChats.map((chat) => {
                            if (chat.ID === event.Payload.ChatID) {
                                return {
                                    ...chat,
                                    ActingUser: {
                                        ...chat.ActingUser,
                                        NumberOfUnreadMessages: 0,
                                    },
                                };
                            }

                            return chat;
                        })
                    );
                } else if (event.MessageType === 'chat.HideChat') {
                    let array = [...this.state.activeChats];
                    const hasChat = array.findIndex((chat) => chat.ID === event.Payload.ChatID) >= 0;
                    if (hasChat) {
                        const index = array.findIndex((chat) => chat.ID === event.Payload.ChatID);
                        if (index >= 0) {
                            array.splice(index, 1);
                            this.props.setActiveChats(array);
                            this.setState({
                                activeChats: array,
                            });
                            dispatch;
                        }
                    }
                } else if (event.MessageType === 'chat.NewMessage') {
                    const { profile } = this.props;

                    const hasChat = this.state.activeChats.findIndex((chat) => chat.ID === event.Payload.ChatID) >= 0;

                    if (
                        event.Payload.Message.Participant.ID !== profile.ID &&
                        this.props.selectedChatId !== event.Payload.ChatID
                    ) {
                        this.applyChangedChats((currentChats) =>
                            currentChats.map((chat) => {
                                if (chat.ID === event.Payload.ChatID) {
                                    return {
                                        ...chat,
                                        ActingUser: {
                                            ...chat.ActingUser,
                                            NumberOfUnreadMessages: chat.ActingUser.NumberOfUnreadMessages + 1,
                                        },
                                    };
                                }

                                return chat;
                            })
                        );
                    }

                    if (!hasChat) {
                        this.loadChat(event.Payload.ChatID);
                    }
                }
            })
        );
    }

    componentWillUnmount() {
        for (const sub of this.subscriptions) {
            sub.unsubscribe();
        }
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.profile && this.props.profile) {
            await this.updateActiveChats();
        }
    }

    loadingChatIds = {};

    async loadChat(chatId) {
        if (this.loadingChatIds.hasOwnProperty(chatId)) {
            return;
        }
        this.loadingChatIds[chatId] = true;

        try {
            const res = await axios.get('/api/profile/chats/' + chatId);
            this.applyChangedChats((currentChats) => {
                const index = currentChats.findIndex((chat) => chat.ID === chatId);
                if (index >= 0) {
                    return currentChats;
                }

                return [...currentChats, res.data];
            });
        } catch (e) {
            Sentry.captureException(e);
        } finally {
            delete this.loadingChatIds[chatId];
        }
    }

    async updateActiveChats() {
        this.setState({ isLoading: true, hasLoadError: false });

        try {
            const res = await axios.get('/api/profile/chats');
            this.applyChangedChats(() => res.data);
        } catch (e) {
            this.props.handleError(e);
            this.setState({ hasLoadError: true });
        } finally {
            this.setState({ isLoading: false });
        }
    }

    applyChangedChats(updateCb) {
        this.setState((prevState) => {
            const activeChats = updateCb(prevState.activeChats);
            activeChats.sort((a, b) => {
                if (a.ActingUser.NumberOfUnreadMessages !== b.ActingUser.NumberOfUnreadMessages) {
                    return b.ActingUser.NumberOfUnreadMessages - a.ActingUser.NumberOfUnreadMessages;
                }

                return 0;
            });
            this.props.setActiveChats(activeChats);
            return { activeChats };
        });
    }

    render() {
        const { isLoading, hasLoadError, activeChats } = this.state;

        const { selectedChatId } = this.props;
        const isOpen = !!selectedChatId;

        const isMobileView = window.innerWidth <= 768;

        return (
            <Box id={isMobileView ? '' : 'chatDrawer'}>
                <Grid
                    sx={{
                        display: 'flex',
                        width: '100%',
                        '@media (max-width: 1024px)': {
                            flexDirection: 'column',
                        },
                    }}
                    spacing={2}
                >
                    <Grid
                        md={5}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            cursor: 'pointer',
                            height: '100vh',
                            width: 80,
                            padding: '0.4rem',
                            // borderRight: '1px solid rgba(255, 255, 255, 0.1)',
                            '@media (max-width: 1024px)': {
                                flexDirection: 'row',
                                width: '100%',
                                height: '12vh',
                            },
                        }}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                '@media (max-width: 1024px)': {
                                    width: '95%',
                                },
                            }}
                        >
                            {activeChats.map((activeChat) => this.renderActiveChat(activeChat))}
                        </Box>
                        {isMobileView && (
                            <Box
                                sx={{
                                    width: '5%',
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    alignItems: 'center',
                                }}
                            >
                                <KeyboardReturnIcon
                                    color="white"
                                    onClick={() => {
                                        this.props.openMsgs(false)(dispatch);
                                    }}
                                />
                            </Box>
                        )}
                    </Grid>
                    <Divider orientation="vertical" />
                    {isOpen && (
                        <>
                            <Grid
                                sx={{
                                    width: '40rem',
                                    position: 'relative',
                                    borderLeft: '1px solid rgba(255, 255, 255, 0.1)',
                                    '@media (max-width: 1024px)': {
                                        width: '100%',
                                        height: '100%',
                                    },
                                }}
                            >
                                <AsyncChatContent chatId={this.props.selectedChatId} />
                            </Grid>
                        </>
                    )}
                </Grid>
            </Box>
        );
    }

    renderActiveChat(activeChat) {
        const { profile } = this.props;

        let displayedLogo, title;
        if (activeChat.Teams.length > 0 && profile && profile.Team) {
            let team = activeChat.Teams.find((t) => profile.Teams.findIndex((myTeam) => myTeam.ID === t.ID) < 0);
            if (!team) {
                team = activeChat.Teams.find((t) => t.ID !== profile.Team.ID);
            }

            if (!team) {
                team = activeChat.Teams[0];
            }

            if (team) {
                displayedLogo = team.HasCustomLogo ? team.Logo : null;
                title = team.Name;
            }
        }

        let shortName = '?';
        if (!displayedLogo) {
            if (title) {
                shortName = title[0] + (title.slice(1).match(/[A-Z]/)?.[0] || title[1]);
            }
        }

        return (
            <ChatButtonBadge
                key={activeChat.ID}
                color="secondary"
                // max={99}
                showZero={false}
                badgeContent={activeChat.ActingUser.NumberOfUnreadMessages}
                sx={{
                    borderRadius: '10px',
                    width: '100%',
                    '@media (max-width: 1024px)': {
                        width: 'auto',
                    },
                }}
            >
                <Box sx={{ width: '100%' }}>
                    <Box
                        style={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            gap: 5,
                            padding: '0.5rem',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            this.props.openChat(activeChat.ID);
                        }}
                    >
                        {displayedLogo ? (
                            <ChatButtonAvatar
                                src={displayedLogo}
                                style={{ borderRadius: this.props.selectedChatId === activeChat.ID ? '15%' : undefined }}
                            />
                        ) : (
                            <ChatButtonAvatar
                                style={{ borderRadius: this.props.selectedChatId === activeChat.ID ? '15%' : undefined }}
                            >
                                {shortName}
                            </ChatButtonAvatar>
                        )}
                        {/* <Box sx={{ width: '100%', cursor: 'pointer' }}>
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Typography sx={{ fontWeight: '700' }}>{title}</Typography>
                            </Box> */}

                        {/* <Typography sx={{ fontWeight: '300' }}>
                              
                                    {moment(this.state.messages.slice(-1)[0].time)
                                        .tz(timezone)
                                        .format(resolveTimeFormatWithWeekdaySt(profile.Settings.TimeFormat))}
                                
                            </Typography> */}
                        {/* </Box> */}
                    </Box>
                    <Divider variant="middle" />
                </Box>
            </ChatButtonBadge>
        );
    }
}

const Connected = connect(
    (state) => ({
        profile: state.app.profile,
        selectedChatId: state.app.selectedChatId,
        websocket: state.app.websocket,
    }),
    (dispatch) =>
        bindActionCreators(
            {
                handleError,
                openChat,
                setActiveChats,
                openMsgs,
            },
            dispatch
        )
)(ChatDrawer);

export default Connected;
