import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Avatar, Button, CircularProgress } from '@mui/material';
import { getTimezoneForProfile } from '../../../../utils/timezone';
import { getMapFromNumber, getMapsForGameId } from '../../../../utils/maps';
import moment from 'moment-timezone';
import styled from 'styled-components';
import * as Sentry from '@sentry/browser';
import { resolveTimeFormatWithWeekdaySt } from '../../../../utils/i18n';

const RequestContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    @media (max-width: 1024px) {
        flex-direction: column;
        align-items: flex-start;
        margin-left: 10px;
        margin-top: 10px;
    }
`;

const TypeContainer = styled.div`
    margin-right: 20px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width: 1024px) {
        flex-direction: row;
        align-items: center;
    }
`;

const TeamNameContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    @media (max-width: 1024px) {
        align-items: center;
    }
`;

const TeamName = styled.div`
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const RequestLabel = styled.div`
    text-align: center;
    @media (max-width: 1024px) {
        margin-left: 5px;
    }
`;

const MapContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
`;

const MobileAvatar = styled(Avatar)`
    display: none;
    width: 30px;
    height: 30px;
    margin-right: 5px;
    @media (max-width: 1024px) {
        display: block;
    }
`;

const DateTimeWrapper = styled.div`
    font-size: 12px;
    font-weight: 700;
    @media (max-width: 1024px) {
        color: grey;
    }
`;

class Request extends React.Component {
    state = {
        isLoading: true,
        isDeleted: false,
        request: null,
    };

    async componentDidMount() {
        await this.loadRequest();
    }

    async loadRequest() {
        const { id } = this.props;

        this.setState({ isLoading: true });
        try {
            const res = await axios.get('/api/requests/' + id);
            this.setState({
                request: res.data,
            });
        } catch (e) {
            if (e.response && e.response.status === 404) {
                this.setState({
                    isDeleted: true,
                });
            } else {
                Sentry.captureException(e);
            }
        } finally {
            this.setState({ isLoading: false });
        }
    }

    render() {
        const { timezone, id, profile, hide } = this.props;

        if (this.state.isLoading) {
            return (
                <div>
                    {/* <CircularProgress size={25} /> */}
                </div>
            );
        }

        if (this.state.isDeleted) {
            return <div>The referenced request (#{id}) was deleted.</div>;
        }

        if (!this.state.request) {
            return (
                <div>
                    Error while loading.
                    <Button variant="outlined" onClick={() => this.loadRequest()}>
                        Retry
                    </Button>
                </div>
            );
        }

        return (
            <RequestContainer>
                {/* {this.renderType()} */}
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {hide ? (
                        <DateTimeWrapper>
                            {moment(this.state.request.Time)
                                .tz(timezone)
                                .format(resolveTimeFormatWithWeekdaySt(profile.Settings.TimeFormat))}
                        </DateTimeWrapper>
                    ) : (
                        this.renderInfo()
                    )}

                    {/* {this.renderActions()} */}
                </div>
            </RequestContainer>
        );
    }

    renderType() {
        const { request } = this.state;

        return (
            <TypeContainer>
                <TeamNameContainer>
                    <MobileAvatar src={request.Team.Logo} />
                    <TeamName>{request.Team.Name}</TeamName>'s
                </TeamNameContainer>
                <RequestLabel>Request</RequestLabel>
            </TypeContainer>
        );
    }

    renderInfo() {
        const { request } = this.state;
        const maps = getMapsForGameId(request.Team.GameID);

        if (maps.length > 0) {
            return (
                <MapContainer>
                    {request.Maps.map((mapNumber) => {
                        const map = getMapFromNumber(request.Team.GameID, mapNumber);

                        return (
                            <div key={mapNumber} style={{ marginRight: '10px', fontSize: '12px', fontWeight: 700 }}>
                                {map.label}
                            </div>
                        );
                    })}
                </MapContainer>
            );
        }

        return (
            <div>
                {request.GamesCount} {request.GamesCount === 1 ? 'Game' : 'Games'}
            </div>
        );
    }

    renderActions() {
        const { request } = this.state;
        const { actingTeamId } = this.props;

        const actions = [];
        //        if (actingTeamId !== request.Team.ID) {
        //            actions.push(<MakeOfferButton request={request} />)
        //        }

        if (actions.length <= 0) {
            return null;
        }

        return (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                {actions}
            </div>
        );
    }
}

const Connected = connect((state) => ({
    websocket: state.app.websocket,
    timezone: getTimezoneForProfile(state.app.profile),
    profile: state.app.profile,
}))(Request);

export default Connected;
